<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>教务中心</a-breadcrumb-item>
      <a-breadcrumb-item>到课/缺勤</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">学员到课</span>
          <attend v-if="activeKey==='1'"/>
        </a-tab-pane>

        <a-tab-pane key="2">
          <span slot="tab">学员缺勤</span>
          <leave v-if="activeKey==='2'"/>
        </a-tab-pane>

        <a-tab-pane key="3">
          <span slot="tab">学员等位</span>
          <wait v-if="activeKey==='3'"/>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import leave from './leave'
import attend from './attend'
import wait from './wait'

export default {
  components: {
    leave,
    attend,
    wait
  },
  data() {
    return {
      activeKey: '1'
    }
  },
  created () {
    if(this.$route.query.activeKey){
      this.activeKey = this.$route.query.activeKey
    }
  },
  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

