<template>
    <a-modal v-model="visible" :title="modalTitle" :footer='null' cancelText="取消" okText="保存" 
    width="800px" @ok="handleOk" @cancel="handleCancel">
        <archives :studentId="item.student_id" :book_id="item.book_id"/>
  </a-modal>
</template>

<script>
    import archives from '@/views/user/profile/archives'
    export default {
        name:"messageModal",
        inject: ['parent'],
        data() {
            return {
                visible: false,
                modalTitle:''
            }
        },
        props: {
            item:Object
        },
        components: {
            archives,
        },
        created () {
            this.visible = true
            this.modalTitle = `课后反馈（${this.item.student_name}）`
        },
        methods: {
            handleOk() {},
            handleCancel(){
                this.parent.hideMessage()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>