<template>
  <a-modal v-model="visible" title="单独补课" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确认排课" width="650px"
    @ok="handleOk" @cancel="handleCancel">
        <a-form :form="form" layout="vertical">
            <a-form-item v-for="(item, index) of formData" :key="index"  :label="item.label">
              <a-select v-if="item.type === 'select'" style='width:300px' placeholder="请选择"
                v-decorator="[item.name, { rules: item.rules }]" allowClear showSearch :filterOption="filterOption">
                <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
              </a-select>
              <a-date-picker style='width:300px' v-else-if="item.type === 'time'" format="YYYY-MM-DD HH:mm" :show-time="{ format: 'HH:mm' }" v-decorator="[item.name, { rules: item.rules }]" />
              <a-button @click="showTeacher" v-if="item.name == 'teacher_id'" style="margin-left:15px" icon="schedule">查看老师空闲时间</a-button>
              <a-button @click="showRoom" v-if="item.name == 'room_id'" style="margin-left:15px" icon="schedule">查看教室空闲时间</a-button>
            </a-form-item>
        </a-form>
      <availableRoom :studio_id="item.studio_id" v-if="isRoom" />
      <availableTeacher :studio_id="item.studio_id" v-if="isTeacher" />
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'course_id',
    label: '课程名称',
    type: 'select',
    rules: [{ required: true, message: '请选择课程!' }],
    items: {
      data: 'courses',
      label: 'filter_name',
      value: 'course_id'
    }
  },
  {
    name: 'teacher_id',
    label: '授课老师',
    type: 'select',
    rules: [{ required: true, message: '请选择授课老师!' }],
    items: {
      data: 'teachers',
      label: 'filter_name',
      value: 'teacher_id'
    }
  },
  {
    name: 'room_id',
    label: '上课教室',
    type: 'select',
    rules: [{ required: true, message: '请选择上课教室!' }],
    items: {
      data: 'rooms',
      label: 'filter_name',
      value: 'room_id'
    }
  },
  {
    name: 'start_date',
    label: '起始时间',
    type: 'time',
    rules: [{ required: true, message: '请选择起始时间!' }],
  },
]
    import moment from 'moment'
    import availableTeacher from '@/views/classes/bjgl/detail/bjpk/availableTeacher'
    import availableRoom from '@/views/classes/bjgl/detail/bjpk/availableRoom'
    export default {
        name:'editModal',
        inject: ['parent'],
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                formData,
                visible:false,
                isRoom:false,
                isTeacher:false,
                confirmLoading:false,
                seleteItems: {
                    courses: [],
                    teachers: [],
                    rooms: [],
                },
                form: this.$form.createForm(this),
            }
        },
        props: {
            item: Object
        },
        components: {
            availableTeacher,
            availableRoom
        },
        created () {
            this.visible = true
            this.getCourseType()
            this.getTeachers()
            this.getRoom()
        },
        methods: {
            moment,
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            async getCourseType() {
                let res = await this.$store.dispatch('searchCourseAction', {params:{class_id:this.item.class_id}})
                this.seleteItems.courses = res.data
            },
            async getTeachers() {
                let res = await this.$store.dispatch('searchTeacherAction', {params:{class_id:this.item.class_id}})
                this.seleteItems.teachers = res.data
            },
            async getRoom() {
                let res = await this.$store.dispatch('searchStudioRoomAction', {params:{class_id:this.item.class_id}})
                this.seleteItems.rooms = res.data
            },
            showTeacher(){
                this.isTeacher = true
            },
            hideTeacher(){
                this.isTeacher = false
            },
            showRoom(){
                this.isRoom = true
            },
            hideRoom(){
                this.isRoom = false
            },
            async handleOk(){
                let params = await this.form.validateFields()
                this.confirmLoading=true
                params.student_id = this.item.student_id
                params.class_id = this.item.class_id
                params.start_date = moment(params.start_date).format('YYYY-MM-DD HH:mm:ss')
                await this.$store.dispatch('classesScheduleAddDateAction', params)
                .then(res=>{
                    this.form.resetFields()
                    this.$message.success('操作成功！！')
                    this.parent.hideEditModal()
                })
                .catch(err=>{
                    this.confirmLoading=false
                })
            },
            handleCancel(){
                this.parent.hideEditModal()
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>